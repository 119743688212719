.dashboardContainer {
    /* margin-left: 300px; */
    /* padding: 50px 16px; */
    /* margin-left: auto; */
    margin-top: 80px;
    /* background-color: #2a6093; */
}

.firstChild {
    /* background: #2433bcde; */
    /* box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.25); */
    /* border-radius: 10px; */
    /* height: 100px; */
    color: #2433bcde;

}

.firstChild > h3 {
    /* text-align: center; */
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    padding: 30px 0 0 0;
}

.pp {
    color: #ffffff;
    font-style: normal;
}

.firstChild > button {
    background: rgba(202, 25, 25, 0.8);
    border-radius: 50px;
    color: #ffffff;
    outline: none;
    border: none;
    margin: 25px;
    padding: 10px 25px;
    font-weight: 600;
    font-size: 18px;
}

.firstChild button a {
    text-decoration: none;
    color: #ffffff;
}

.secondChild {
    margin-top: 65px;
}

.secondChild div {
    background: #ffffff;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    /* width: 352px; */
    /* min-height: 152px; */
    /* margin: 0 30px; */
    padding: 25px;
    border-radius: 5px;
    font-size: 25px;
    color: #000000;
}

.secondChild div > span {
    display: block;
    color: #5254d9;
}

.thirdChild {
    margin-top: 70px;
}

 .leftThirdChild {
    background: linear-gradient(
        90deg,
        #5254d9 66.41%,
        rgba(12, 34, 13, 0) 156.8%
    );
    border-radius: 10px;
    color: #ffffff;
    /* padding: 1rem; */
}

.leftThirdChild h4 {
    font-size: 30px;
    /* width: 500px; */
    line-height: 40px;
   
}

.leftThirdChild h4 > span {
    /* display: block; */
    /* margin-left: 15px; */



}
.rightThirdChild {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
}

.rightThirdChild div {
    background: #ffffff;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    /* height: 110px; */
    margin-left: 60px;
    margin-bottom: 20px;
    padding: 30px;
    font-size: 23px;
    color: #050505;
}

.rightThirdChild .requests span {
    display: block;
    color: #2ec321;
}

.rightThirdChild .pending span {
    display: block;
    color: #ff0000;
}

.rightThirdChild .fa-circle-check {
    
    color: #2CAC21;
}

.rightThirdChild .fa-triangle-exclamation {
   
    color: #e8c915;
}

.attendance {
    background: #2a6093;
    border-radius: 10px;
    width: 323px;
    height: 121px;
    margin-bottom: 40px;
    color: #ffffff;

    font-weight: 700;
    font-size: 26px;
    line-height: 37px;
    padding: 25px 20px;
}

.attendance,
.payroll div {
    font-weight: 400;
    font-size: 20px;
}

.attendance div > span {
    padding-left: 30px;
}
.payroll div > span {
    padding-left: 30px;
}
