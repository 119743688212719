.profileContainer {
    padding: 1px 16px;
    margin-top: 50px;
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 700;
}

.profileContainer .empDetailsContainer {
    background: #ffffff;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
    border: solid 1px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
    margin-top: 30px;
    display: flex;
    justify-content: space-around;
    padding: 20px 40px;
    width: 100%;
}

h4 {
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 700;
}

.empDetailsContainer .leftEmpDetails {
    width: 70%;
}

.leftEmpDetails h6 {
    margin: 20px;
}

.rightEmpDetails h6 {
    margin: 20px;
}

.empDetailsContainer .rightEmpDetails {
    width: 50%;
}

.leftEmpDetails h6 {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.leftEmpDetails span:first-child {
    width: 30%;
    font-weight: 700;
}

.leftEmpDetails span:last-child {
    width: 70%;
    font-weight: 400;
}

.empStatus button {
    outline: none;
    border: none;
    background: #ffffff;
    border-radius: 25px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    padding: 5px 10px;
    margin-right: 20px;
}

.rightEmpDetails h6 {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.rightEmpDetails span:first-child {
    width: 50%;
    font-weight: 700;
}

.rightEmpDetails span:last-child {
    width: 50%;
    font-weight: 400;
}

.rightEmpDetails .annualCom {
    color: rgba(202, 25, 25, 0.8);
}

.profileContainer .empOtherDetails {
    margin-top: 40px;
    display: flex;
    /* justify-content: space-between; */
    width: 100%;
}

.empOtherDetails .empTransactions {
    width: 45%;
}

.empTransactions .empTranMonth {
    text-align: center;
    width: 80%;
    background: #ffffff;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    border-radius: 5px 5px 0 0;
}

.empTranMonth .detailsMonth {
    padding: 5px;
    margin: 15px 0;
    border-radius: 10px;
    width: 120px;
    outline: none;
    border: none;
    color: #ffffff;
    background: #52acfe;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.empTransactions .table {
    border-collapse: collapse;
    text-align: left;
    background: #ffffff;
    /* box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25); */
    border-radius: 5px;
    width: 80%;
}

.empTransactions .empTransactionTableData {
    border-collapse: collapse;
    padding-left: 30px;
}

.empOtherDetails .empBankDetails {
    width: 45%;
}

.empBankDetails .empBankDetailsContainer {
    background: #ffffff;
    box-shadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    /* margin-top: 30px; */
    padding: 5px 25px;
}

.empBankDetailsContainer h6 {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin: 20px;
}

.empBankDetailsContainer span:first-child {
    width: 50%;
    font-weight: 700;
}

.empBankDetailsContainer span:last-child {
    width: 50%;
    font-weight: 400;
}
