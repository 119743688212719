.attendanceContainer {
    margin-left: 350px;
    padding: 1px 16px;
    margin-top: 100px;
}

.attendanceUpperPart {
    display: flex;
    justify-content: space-between;
    width: 100%;
    overflow-x: auto;
}

.attendanceContainer .attendanceTable {
    width: 60%;
}

.attendanceTable .table {
    border-collapse: collapse;
    text-align: left;
    background: #ffffff;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.attendanceTable .attendanceTableData {
    border-collapse: collapse;
    padding-left: 30px;
}

.attendanceTable tbody tr:hover {
    background: #5d5fef;
    color: #ffffff;
}

.attendanceContainer .rightAttendance {
    width: 38%;
}

.rightAttendance {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.rightAttendance .attendanceBtn {
    margin-top: 30px;
}

.attendanceBtn button {
    margin-top: 20px;
    border-radius: 10px;
    border: none;
    outline: none;
    width: 100%;
    text-align: center;
    padding: 15px 40px;
    color: #ffffff;
    font-weight: 700;
    font-size: 19px;
}

.uploadBtn {
    background: rgba(15, 61, 130, 0.8);
    text-align: center;
}

.downloadBtn {
    background: #2cac21;
    text-align: center;
    color: white;
    text-decoration: none;
}

.monthText {
    font-size: 26px;
    font-weight: bold;
}

.tableSelected {
    background: #5d5fef;
    color: #ffffff;
}

.react-calendar {
    width: 100% !important;
    max-width: 100%;
    color: #222;
    border-radius: 10px;
    background: #ffffff;
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.25);
    line-height: 1.125em;
    padding: 10px;
    border: none !important;
    outline: none !important;
}

/* .react-calendar__tile {
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  color: #52acfe;
  border-radius: 50%;
  padding: 22px 0px !important;
  font-weight: 600;
  border: none !important;
  outline: none !important;
  font-size: 18px;
  width: 200px !important;
  cursor: default !important;
} */

.react-calendar__month-view__days {
    display: grid !important;
    grid-template-columns: 14.2% 14.2% 14.2% 14.2% 14.2% 14.2% 14.2%;
}

.react-calendar__month-view__days .react-calendar__tile {
    max-width: initial !important;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    color: #52acfe;
    width: 50px;
    height: 50px;
    text-align: center;
    border-radius: 28%;
    padding: 15px 0px !important;
    margin: 8px;
    font-weight: 600;
    border: none !important;
    outline: none !important;
    font-size: 18px;
    cursor: default !important;
}

.react-calendar button {
    margin: 3px;
    border: 0;
    border-radius: 3px;
    padding: 5px 0;
}

.react-calendar__month-view__days__day--neighboringMonth {
    opacity: 0.5;
}

.absentMark {
    background: red !important;
    border-radius: 50%;
    color: white !important;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.presentMark {
    background: green !important;
    border-radius: 50%;
    color: white !important;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.selectionMark {
    background: blue !important;
    border-radius: 50%;
    color: white !important;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.bottomNavigation {
    z-index: -1 !important;
}

@media screen and (max-width: 1000px) {
    .attendanceContainer {
        margin-left: 200px;
        padding: 0;
    }
    .attendanceTable {
        width: 90% !important;
    }
    .attendanceUpperPart {
        display: flex;
        flex-direction: column;
        margin: 0 !important ;
        padding: 0 !important;
    }
}
