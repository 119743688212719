.topContainer {
    left: 0px;
    top: 0px;
    background: #5d5fef;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    display: flex;
    position: sticky;
    justify-content: space-between;
    padding: 30px 50px;
    z-index: 1;
}

.fontf {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.leftChild {
    color: #ffffff;
    font-style: normal;
    font-weight: 700;
    font-size: 43px;
    line-height: 49px;
    font-family: 'Montserrat';
}

.rightChild {
    display: flex;
    align-items: center;
}
.m7{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
}

.rightChild a {
    text-decoration: none;
    display: flex;
    align-items: center;
}

.rightChild input {
    margin-right: 20px;
    border: none;
    outline: none;
    background: #ffffff;
    box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
    padding: 10px;
    padding-left: 25px;
}

.rightChild img {
    border-radius: 50%;
}

.rightChild div {
    /* color: #ffffff; */
    /* font-family: "Ubuntu"; */
    /* font-style: normal; */
    /* font-weight: 700; */
    font-size: 25px;
    /* padding-left: 15px; */
}
