@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@400;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap");
@import url("https://use.fontawesome.com/releases/v5.15.4/css/all.css");
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@700&display=swap');


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: default;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  cursor: pointer;
}

.f36 {
  font-family: 'Source Sans 3';
  font-weight: 600;
}

.f37 {
  font-family: 'Source Sans 3';
  font-weight: 700;
}

.f34 {
  font-family: 'Source Sans 3';
  font-weight: 400;
}

.m7 {
  font-family: 'Montserrat';
  font-weight: 700;
}

.montserrat {
  font-family: 'Montserrat';
}

.n7 {
  font-family: 'Nunito';
  font-weight: 700;
}

.nunito {
  font-family: 'Nunito';
}

.s7 {
  font-family: 'Source Sans Pro';
  font-weight: 700;
}

.s4 {
  font-family: 'Source Sans Pro';
  font-weight: 400;
}

.table th:first-child {
  z-index: 0 !important;
}

.page-item.active .page-link {
  z-index: 0 !important;
  background-color: #5D5FEF;
  border-color: #5D5FEF;
}

/* .table td {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
}

.table thead th {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 31px;
}

tr {
  background: #FCFCFC;
  border: 0.5px solid #E7E7E7;
  box-sizing: border-box;
  box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
} */

.login-right {
  width: 50%;
  height: 100vh;
  display: flex;
  background-color: #2433bc8f;
}

.login-left {
  width: 50%;
  height: 100vh;
  display: flex;
  background-color: #2433bc0a;
}
