.sidebar {
    margin: 0;
    padding: 0;
    width: 200px;
    background-color: #5D5FEF;
    position: fixed;
    height: 100%;
    

    color: #FFFFFF;
    overflow-y: scroll;
    /* z-index: -1; */
  }
  
  .ellipse{
    position: relative;
    left: 180px;
    top: -20px;
  }

  .sidebar a {
    display: flex;
    color: #FFFFFF;
    padding: 16px;
    text-decoration: none;
    text-align: justify;
  }
   
  /* .sidebar a.active {
    background-color: #5254D9;
    color: white;
  } */
  
  .sidebar .active {
    background-color: #5254D9;
    color: white;
  }

  .sidebar a:hover:not(.active) {
    color: white;
  }
  
  .accordion-button:not(.collapsed) {
    color: white;
    background-color: #5d5fef;
    box-shadow: none;
}
.accordion-button:focus {
  z-index: 3;
  border: none;
  outline: 0;
  box-shadow: none;
}

.accordion-button::after {
  fill: white;
  color: white;
}