.approvalsContainer {
    margin-left: 350px;
    padding: 1px 16px;
    margin-top: 50px;
}

.pendingTable {
    margin-top: 50px;
}

.pendingTable .approveBtn {
    outline: none;
    border: none;
    box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.25);
    background: #2cac21;
    border-radius: 10px;
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 700;
    color: #ffffff;
    width: 120px;
    margin-right: -40px;
    height: 38px;
}
.pendingTable .processBtn {
    outline: none;
    border: none;
    box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.25);
    background: #fedd15;
    border-radius: 10px;
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 600;
    color: white;
    width: 120px;
    margin-right: -40px;
    height: 38px;
}

.pendingTable .rejectBtn {
    outline: none;
    border: none;
    box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.25);
    background: #ca1919;
    border-radius: 10px;
    color: #ffffff;
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 700;
    width: 120px;
    height: 38px;
}

.rejectedTable {
    margin-top: 50px;
}

.rejectedTable .rejectBtn {
    outline: none;
    border: none;
    box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.25);
    color: #ca1919;
    border-radius: 10px;
    background: #ffffff;
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 700;
    width: 120px;
    height: 38px;
}

.approvedTable {
    margin-top: 50px;
}

.approvedTable .approveBtn {
    outline: none;
    border: none;
    box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.25);
    color: #2cac21;
    border-radius: 10px;
    background: #ffffff;
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 700;
    width: 120px;
    height: 38px;
}

.tableDataCard td:first-child {
    text-decoration: underline;
}
