.employeeContainer {
  margin-left: 350px;
  padding: 1px 16px;
  margin-top: 50px;
}

.roww:hover {
  background: #5d5fef;
  color: #ffffff;
}

.roww a {
  text-decoration: underline;
  color: inherit;
}
.roww:hover a {
  background: #5d5fef;
  color: #ffffff;
}
/* 

.rowww:hover {
  background: #2563eb;
  color: #ffffff;
}

.rowww a {
  text-decoration: underline;
  color: inherit;
}
.rowww:hover a {
  /* background: #2433bc8f; */
  /* color: #ffffff; */
/* } */ 

.bottomNavigation {
  display: flex;
  justify-content: space-between;
}

.pagination .page-link {
  margin: 15px 10px;
  border: 1px solid #929292;
  box-sizing: border-box;
  border-radius: 5px;
  color: #929292;
  font-family: 'Source Sans 3';
  font-weight: 400;
  font-size: 16px;
line-height: 19px;

}

.addEmpBtn {
  background: rgba(202, 25, 25, 0.8);
  border-radius: 10px;
  color: #ffffff;
  outline: none;
  border: none;
  padding: 0 20px;
  height: 40px;
  margin: 15px 10px;
  text-align: center;
}

.addEmployee {
  display: flex;
}

.addEmpCardNotActive {
  display: none;
}

.addEmpCardActive {
  text-align: center;
  display: flex;
  flex-direction: column;
  background: #fcfcfc;
  border: 0.5px solid #e7e7e7;
  box-sizing: border-box;
  box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 20px;
  animation-name: addEmpCardAnimation;
  animation-duration: 0.2s;
}

@keyframes addEmpCardAnimation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.addEmpCardActive button {
  margin: 10px;
  border-radius: 10px;
  border: none;
  outline: none;
  padding: 10px 40px;
  text-align: center;
  color: #ffffff;
  font-weight: 700;
  font-size: 18px;
  width: 280px;
}

.addEmpCardActive .uploadBtn {
  background: rgba(15, 61, 130, 0.8);
  
}

.addEmpCardActive .downloadBtn {
  background: #2cac21;
}

