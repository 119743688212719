.TopApprovalNavContainer .TopApprovalLink {
  text-decoration: none;
  font-family: 'Source Sans 3';
font-style: normal;
font-weight: 600;
  font-size: 22px;
  line-height: 31px;
  color: #000000;
  padding: 0 20px;
}

.TopApprovalNavContainer .active {
    padding-bottom: 10px;
    border-bottom-style: solid;
    border-bottom-width: 2px;
  }