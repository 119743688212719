.settingsContainer {
    margin-left: 350px;
    padding: 1px 16px;
    margin-top: 100px;
}

.salaryDes {
    display: flex;
    width: 100%;
    margin-top: 20px;
}

.salaryDes p {
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #000000;
    width: 40%;
}

.salaryDes .salaryButtons button {
    outline: none;
    border: none;
    margin: 10px;
    font-weight: 500;
    font-size: 16px;
    color: #2cac21;
    border-radius: 10px;
    padding: 5px 15px;
    background: #ffffff;
    box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.25);
}

/* .salaryDes .salaryButtons button:hover{
    background: #5254D9;
    color: #ffffff;
} */

.salaryDes .salaryButtons input {
    width: 4rem;
    margin-left: 20px;
    background: #ffffff;
    box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.25);
    outline: none;
    border: none;
    border-radius: 10px;
    padding: 5px 15px;
}

.salaryDes .salaryButtons span {
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #000000;
}

.admin {
    margin-top: 90px;
}

.adminCard {
    background: #ffffff;
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
    display: flex;
    margin-top: 30px;
    padding: 20px;
    justify-content: space-between;
}

.adminCard .adminCardDetails {
    display: flex;
    font-weight: 500;
    font-size: 18px;
    line-height: 40px;
    color: #000000;
}

.adminCard .adminCardDetails p {
    margin: 5px 50px;
}
h4 {
    font-weight: 700;
    font-size: 28px;
    font-family: 'Source Sans 3';
    line-height: 40px;
}
.adminCard .adminCardDetails span {
    display: block;
    font-weight: 300;
}

.approval {
    margin-top: 90px;
}

.approvalDes {
    display: flex;
    width: 100%;
    margin-top: 20px;
}

.approval p {
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #000000;
    width: 40%;
}

.approvalDes .approvalButtons button {
    outline: none;
    border: none;
    margin: 10px;
    font-weight: 500;
    font-size: 16px;
    color: #2cac21;
    border-radius: 10px;
    padding: 5px 15px;
    background: #ffffff;
    box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.25);
}

/* .approvalDes .approvalButtons button:hover{
    background: #5254D9;
    color: #ffffff;
} */

.approvalDes .approvalButtons input {
    width: 4rem;

    margin-left: 20px;
    background: #ffffff;
    box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.25);
    outline: none;
    border: none;
    border-radius: 10px;
    padding: 5px 15px;
}

.approvalDes .approvalButtons span {
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #000000;
    margin-left: 20px;
}

.saveChangesBtn {
    background: rgba(202, 25, 25, 0.8);
    border-radius: 10px;
    color: #ffffff;
    outline: none;
    border: none;
    padding: 0px 20px;
    height: 40px;
    margin-top: 30px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
