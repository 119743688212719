.popup-box {
    position: fixed;
    width: 100%;
  }
   
  .box {
    position: relative;
    width: 40%;
    top: -50px;
    left: -195px;
    margin: 0 auto;
    height: auto;
    max-height: 70vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    border: 1px solid gray;
    overflow: auto;
    box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.25);
  }
   
  .close-icon {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: calc(15% + 95px);
    top: calc(100vh - 85vh + 80px);
    background: #ededed;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    font-size: 20px;
  }