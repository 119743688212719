.transactionsContainer {
    margin-left: 350px;
    padding: 1px 16px;
    margin-top: 50px;
}

.transactionsContainer table {
    text-align: left;
}

.transactionsContainer .tableDataCard td:first-child {
    text-decoration: underline;
}

.transactionsContainer .tableDataCard td:last-child {
    text-decoration: underline;
    cursor: pointer;
    font-weight: 700;
    color: #4b6dc5;
}

.tableDataCard:hover td {
    color: #ffffff !important;
}

.viewDetail {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700 !important;
    font-size: 18px;
    line-height: 28px;
    /* identical to box height */


    color: #4B6DC5;
}

.exportData {
    outline: none;
    border: none;
    color: #2cac21;
    filter: drop-shadow(0px 0px 7px rgba(0, 0, 0, 0.25));
    border-radius: 10px;
    font-weight: 700;
    font-size: 18px;
    background-color: white;
    padding: 12px 20px;
}

.detailsTable .detailsMonth {
    float: right;
    padding: 5px;
    border-radius: 10px;
    background: none;
    width: 120px;
    outline: none;
}

.detailsTable .table {
    border: 1px solid black;
    border-collapse: collapse;
}

.detailsTable .detailsTableData {
    border: 1px solid black;
    border-collapse: collapse;
    padding-left: 30px;
}